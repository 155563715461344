import { clsx } from 'clsx';

import type { TextLinkProps } from './type';

export const THEME_CLASS_NAMES = ({ theme }: Pick<TextLinkProps, 'theme'>) => {
  switch (theme) {
    case 'black': {
      return clsx('tw-underline hover:tw-no-underline');
    }
    case 'blue': {
      return clsx('tw-text-link');
    }
    default:
      return '';
  }
};
