import type { IconBaseProps } from '@/components/icons/type';

export const IconSNSTwitter = ({
  boxSize = '2.5em',
  color = '#1C1C1C',
}: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_716_23634)">
        <path
          d="M20 0C14.6957 0 9.60861 2.10712 5.85788 5.85785C2.10715 9.60858 0 14.6957 0 20C0 25.3043 2.10715 30.3914 5.85788 34.1421C9.60861 37.8928 14.6957 40 20 40C25.3043 40 30.3914 37.8928 34.1422 34.1421C37.8929 30.3914 40 25.3043 40 20C40 14.6957 37.8929 9.60858 34.1422 5.85785C30.3914 2.10712 25.3043 0 20 0ZM30 16.21C30 16.42 30 16.62 30 16.83C30.0172 18.63 29.6776 20.4155 29.001 22.0836C28.3243 23.7516 27.324 25.2691 26.0576 26.5485C24.7913 27.8278 23.2841 28.8436 21.6231 29.5373C19.9621 30.231 18.1801 30.5888 16.38 30.59C13.7593 30.5922 11.1935 29.8387 8.98999 28.42C9.37138 28.4685 9.75556 28.4919 10.14 28.49C12.3145 28.496 14.4278 27.7704 16.14 26.43C15.1336 26.4087 14.1587 26.0748 13.3506 25.4745C12.5424 24.8743 11.9411 24.0374 11.63 23.08C11.9303 23.1352 12.2347 23.1653 12.54 23.17C12.969 23.1702 13.3961 23.1131 13.81 23C12.7202 22.7752 11.7409 22.1824 11.0363 21.3212C10.3317 20.46 9.94458 19.3827 9.94 18.27V18.21C10.6112 18.583 11.3624 18.7888 12.13 18.81C11.4693 18.3725 10.9272 17.7783 10.552 17.0803C10.1769 16.3824 9.98037 15.6024 9.98001 14.81C9.97887 13.9556 10.2068 13.1165 10.64 12.38C11.8521 13.863 13.3626 15.0746 15.0733 15.9359C16.784 16.7973 18.6568 17.2893 20.57 17.38C20.4889 17.019 20.4487 16.65 20.45 16.28C20.45 15.0017 20.9578 13.7757 21.8618 12.8717C22.7657 11.9678 23.9917 11.46 25.27 11.46C25.9293 11.4602 26.5817 11.5952 27.187 11.8566C27.7922 12.1179 28.3377 12.5003 28.79 12.98C29.8712 12.7742 30.9073 12.378 31.85 11.81C31.4922 12.9285 30.7383 13.878 29.73 14.48C30.6866 14.369 31.6207 14.1127 32.5 13.72C31.8376 14.6922 31.0041 15.5359 30.04 16.21H30Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
