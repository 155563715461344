import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { useLockedBody } from 'usehooks-ts';

import type { BaseModalProps } from './type';
import type { MouseEventHandler } from 'react';

import { IconClose } from '@/components/icons';
import { ModalOverlay } from '@/components/ui/modal/ModalOverlay';

export const BaseModal = ({
  isOpen,
  title,
  ariaLabel,
  closeOnOverlayClick = true,
  withCloseButton = true,
  maxWidthPx,
  onClose,
  children,
}: BaseModalProps) => {
  useLockedBody(isOpen, 'root');

  const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!closeOnOverlayClick) return;
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClickCloseButton = () => {
    onClose();
  };

  return (
    <>
      {isOpen && <ModalOverlay />}
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx('tw-transition-opacity tw-duration-200')}
        enterFrom={clsx('tw-opacity-0')}
        enterTo={clsx('tw-opacity-100')}
        leave={clsx('tw-transition-opacity tw-duration-200')}
        leaveFrom={clsx('tw-opacity-100')}
        leaveTo={clsx('tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-flex tw-items-center tw-justify-center tw-min-h-[100dvh]'
            )}
            onClick={handleClickOverlay}
          >
            <div
              role="dialog"
              aria-modal
              aria-label={ariaLabel}
              className={clsx(
                'tw-relative tw-py-6 tw-px-4 tw-mx-spContentSide tw-w-full tw-bg-white tw-rounded-2xl',
                'md:tw-min-w-[440px] md:tw-w-auto md:tw-p-10'
              )}
              style={{
                maxWidth:
                  maxWidthPx !== undefined ? `${maxWidthPx}px` : undefined,
              }}
            >
              {withCloseButton && (
                <button
                  className={clsx(
                    'tw-absolute tw--top-10 tw-right-0 tw-text-white',
                    'hover:tw-opacity-70 tw-transition tw-duration-200'
                  )}
                  onClick={handleClickCloseButton}
                >
                  <IconClose />
                </button>
              )}
              {title != null && (
                <div
                  className={clsx(
                    'tw-text-head20 tw-font-bold tw-mb-10 tw-text-center'
                  )}
                >
                  {title}
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
