import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';

import type { AuthSignupFormProps, AuthSignupFormVO } from './type';

import { generateVerificationEmail } from '@/components/features/auth/AuthSignupForm/util';
import {
  FormFieldText,
  Button,
  TextLink,
  FormFieldCheckbox,
} from '@/components/ui';
import { ROUTE } from '@/constants';
import { AuthSignupFormSignupDocument } from '@/graphql/generated';
import { useToast } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

import {
  authSignupSchema,
  formInitialValues,
  formLabel,
  placeholder,
  MIN_LENGTH,
} from './const';

export const AuthSignupForm = ({
  onAfterSignup: onAfterSignup,
}: AuthSignupFormProps) => {
  const [signupMutation, signupMutationState] = useMutationWrapper(
    AuthSignupFormSignupDocument,
    {
      role: 'anonymous',
    }
  );

  const { showToast } = useToast();

  const { control, handleSubmit } = useForm<AuthSignupFormVO>({
    mode: 'onBlur',
    resolver: zodResolver(authSignupSchema),
    defaultValues: formInitialValues,
  });

  const submitHandler = async (data: AuthSignupFormVO) => {
    signupMutation({
      variables: {
        user: {
          email: data.email,
          password: data.password,
        },
        verificationEmail: generateVerificationEmail(),
        receivesNewsletter: data.receivesNewsletter,
      },
      onCompleted: () => {
        showToast('success', '認証メールを送信しました');
        onAfterSignup();
      },
    });
  };

  return (
    <form className={clsx('tw-space-y-4')}>
      <FormFieldText
        name="email"
        label={formLabel.email}
        isRequired
        placeholder={placeholder.email}
        control={control}
        onKeyDownEnter={handleSubmit(submitHandler)}
      />
      <FormFieldText
        name="password"
        type="password"
        label={formLabel.password}
        isRequired
        placeholder={placeholder.password}
        helperText={`半角英数字${MIN_LENGTH.PASSWORD}文字以上`}
        control={control}
        onKeyDownEnter={handleSubmit(submitHandler)}
      />
      <FormFieldCheckbox
        name="receivesNewsletter"
        showLabel={false}
        control={control}
        checkboxLabel={
          <div className={clsx('tw-text-left')}>
            {formLabel.receivesNewsletter}
          </div>
        }
      />
      <p>
        <TextLink theme="blue" href={ROUTE.sitePolicy()}>
          利用規約
        </TextLink>
        と
        <TextLink theme="blue" href={ROUTE.privacyAgreement()}>
          個人情報のお取り扱いに関する同意事項
        </TextLink>
        に同意した方のみ送信してください
      </p>
      <Button
        theme="fill"
        onClick={handleSubmit(submitHandler)}
        isLoading={signupMutationState.loading}
      >
        無料会員登録
      </Button>
    </form>
  );
};
