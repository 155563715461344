import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { ButtonProps } from './type';

import { IconLoader } from '@/components/icons';
import { HeadlessButton } from '@/components/ui';
import { BUTTON_CLASS_NAMES } from '@/components/ui/button/const';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      theme = 'fill',
      size = 'md',
      rounded = 'md',
      roundedDirection = {
        topLeft: true,
        topRight: true,
        bottomLeft: true,
        bottomRight: true,
      },
      leftIcon,
      isLoading = false,
      isBlock = true,
      disabled = false,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <HeadlessButton
        ref={ref}
        disabled={disabled || isLoading}
        className={clsx(
          BUTTON_CLASS_NAMES({
            theme,
            size,
            rounded,
            roundedDirection,
          }),
          isBlock && 'tw-w-full'
        )}
        {...props}
      >
        <span className={clsx('tw-mr-1')}>
          {isLoading ? <IconLoader /> : leftIcon}
        </span>
        {children}
      </HeadlessButton>
    );
  }
);

Button.displayName = 'Button';
