import { ROUTE, SITE } from '@/constants';

export const generateVerificationEmail = () => {
  const placeholder = {
    verificationToken: '{{verificationToken}}',
    receivesNewsletter: '{{receivesNewsletter}}',
  };
  const redirectUrl = `${SITE.DOMAIN}${ROUTE.mypageTop('?onboarding=true')}`;
  return {
    from: { name: 'Koubo' },
    subject: 'Koubo メールアドレス認証を完了してください',
    body: `※このメールは公募ガイド社が運営するシステムから自動送信しています。

あなたが Koubo に新たにアカウントを作成しました。本メールアドレスがあなたのものであることを確認するため、以下のリンクをクリックして認証を完了してください。

${SITE.DOMAIN}${ROUTE.emailVerification()}?token=${
      placeholder.verificationToken
    }&receivesNewsletter=${
      placeholder.receivesNewsletter
    }&redirectUrl=${encodeURIComponent(redirectUrl)}

このリンクはメール送信から1時間以内にのみ有効です。時間内にリンクをクリックして認証を完了してください。なお、あなたのアカウントは、上記のリンクをクリックして認証が完了するまで完全に有効化されません。

このメールに心当たりがない場合、あなたのメールアドレスが誤って使用された可能性があります。その場合は、このメールを無視してください。

―――――――――――――――――――――――――――――――――――
運営会社：株式会社公募ガイド社
https://koubo.co.jp
`,
    placeholder,
  };
};
