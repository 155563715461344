import type { IconBaseProps } from '@/components/icons/type';

export const IconLoader = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tw-animate-spin"
    >
      <g clipPath="url(#clip0_3096_134653)">
        <g clipPath="url(#clip1_3096_134653)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 12C19 8.13401 15.866 5 12 5V3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12H5C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
