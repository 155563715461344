import type { IconBaseProps } from '@/components/icons/type';

export const IconSNSFacebook = ({
  boxSize = '2.5em',
  color = '#1C1C1C',
}: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_716_23635)">
        <path
          d="M20 0C14.6957 0 9.60858 2.12 5.85785 5.89364C2.10712 9.66728 2.82062e-07 14.7854 2.82062e-07 20.1222C-0.00079921 24.9139 1.69802 29.5487 4.79082 33.1926C7.88363 36.8365 12.1674 39.2504 16.8714 40V25.936H11.8V20.1222H16.8714V15.6881C16.8714 10.6576 19.8643 7.86202 24.4286 7.86202C25.9288 7.88294 27.4255 8.01263 28.9071 8.25009V13.2088H26.3857C23.9 13.2088 23.1286 14.7539 23.1286 16.3493V20.1222H28.6714L27.7857 25.936H23.1286V40C27.8326 39.2504 32.1164 36.8365 35.2092 33.1926C38.302 29.5487 40.0008 24.9139 40 20.1222C40 14.7854 37.8929 9.66728 34.1422 5.89364C30.3914 2.12 25.3043 0 20 0Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
