import {
  useQuery,
  useLazyQuery,
  useMutation,
  type OperationVariables,
  type DocumentNode,
  type TypedDocumentNode,
  type QueryResult,
  type MutationHookOptions,
  type MutationTuple,
  type LazyQueryHookOptions,
  type LazyQueryResultTuple,
} from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import type { Role } from '@/types/auth';
import type { QueryHookOptions } from '@apollo/client';
import type { GraphQLError } from 'graphql';

import { ROUTE } from '@/constants';
import { useToast } from '@/hooks/utils/useToast';

const FILTER_TARGET_ERROR_CODES = [
  'validation-failed',
  'invalid-jwt',
  'unauthorized',
];

export const useHandleGraphQLError = () => {
  const router = useRouter();
  const { showToast } = useToast();
  const showErrorToast = useCallback(
    (errors: readonly GraphQLError[]) => {
      const filteredErrors = errors.filter(
        (error) =>
          typeof error.extensions.code === 'string' &&
          FILTER_TARGET_ERROR_CODES.includes(error.extensions.code)
      );
      // NOTE: 個別メッセージを出すエラーを含まない場合
      if (filteredErrors.length === 0) {
        showToast('error', '時間をおいて再度お試しください', {
          id: 'unknownError',
        });
        return;
      }

      if (
        filteredErrors.some((error) => error.extensions.code === 'invalid-jwt')
      ) {
        showToast('error', '再ログインしてください');
        router.push(ROUTE.top());
        return;
      }

      const messageNode = (
        <ul>
          <li>エラーが発生しました</li>
          {filteredErrors.map((error) => (
            <li key={error.name}>{error.message}</li>
          ))}
        </ul>
      );
      showToast('error', messageNode);
    },
    [router, showToast]
  );
  return {
    showErrorToast,
  };
};

export const useQueryWrapper = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & { role?: Role }
): QueryResult<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  return useQuery(query, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...(options?.role
      ? { context: { headers: { 'x-hasura-role': options.role } } }
      : {}),
    ...options,
  });
};

export const useLazyQueryWrapper = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> & { role?: Role }
): LazyQueryResultTuple<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  return useLazyQuery(query, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...(options?.role
      ? { context: { headers: { 'x-hasura-role': options.role } } }
      : {}),
    ...options,
  });
};

export const useMutationWrapper = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & { role?: Role }
): MutationTuple<TData, TVariables> => {
  const { showErrorToast } = useHandleGraphQLError();
  return useMutation(mutation, {
    onError(error) {
      showErrorToast(error.graphQLErrors);
    },
    notifyOnNetworkStatusChange: true, // NOTE: refetchの検知をするため
    ...(options?.role
      ? { context: { headers: { 'x-hasura-role': options.role } } }
      : {}),
    ...options,
  });
};
