import { useController } from 'react-hook-form';

import type { FormFieldTextProps } from './type';

import { FormFieldWrapper, InputText } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldText = ({
  name,
  control,
  shouldUnregister,
  defaultValue,
  onAfterBlur,
  ...props
}: FormFieldTextProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name,
  });

  const handleBlur = () => {
    field.onBlur();
    if (onAfterBlur !== undefined) {
      onAfterBlur();
    }
  };
  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputText
        data-testid={wrapProps.id}
        id={wrapProps.id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef={ref}
        state={error !== undefined ? 'error' : undefined}
        {...inputProps}
        {...field}
        onBlur={handleBlur}
      />
    </FormFieldWrapper>
  );
};
