import { clsx } from 'clsx';

import type { AuthLoginModalProps } from './type';

import { AuthLoginForm } from '@/components/features/auth';
import { BaseModal, TextLink, AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import SignupBanner from 'public/images/signup_banner.svg';

export const AuthLoginModal = ({
  isOpen,
  onClose,
  onClickSignup,
  onAfterLogin,
}: AuthLoginModalProps) => {
  const handleAfterLogin = () => {
    onClose();
    if (onAfterLogin !== undefined) {
      onAfterLogin();
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title="ログイン"
      ariaLabel="ログイン"
      onClose={onClose}
    >
      <AuthLoginForm onAfterLogin={handleAfterLogin} />
      <section className={clsx('tw-mt-6 tw-flex tw-justify-center')}>
        <TextLink theme="blue" href={ROUTE.forgetPassword()}>
          パスワードをお忘れの方
        </TextLink>
      </section>
      <section className={clsx('tw-mt-6')}>
        <div
          onClick={onClickSignup}
          className={clsx(
            'hover:tw-opacity-70 tw-transition tw-duration-200 tw-cursor-pointer'
          )}
        >
          <AnchorLink href={ROUTE.service()}>
            <SignupBanner />
          </AnchorLink>
        </div>
      </section>
    </BaseModal>
  );
};
