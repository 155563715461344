import { LoginDocument } from '@/graphql/generated';
import { useToast } from '@/hooks/utils';
import { useHandleGraphQLError, useMutationWrapper } from '@/libs/apollo';

export const useLogin = () => {
  const { showToast } = useToast();
  const { showErrorToast } = useHandleGraphQLError();

  return useMutationWrapper(LoginDocument, {
    refetchQueries: 'active',
    role: 'anonymous',
    onError: ({ graphQLErrors }) => {
      const unauthorizedError = graphQLErrors.find(
        (error) => error.extensions.code === 'unauthorized'
      );
      if (unauthorizedError) {
        showToast('error', unauthorizedError.message);
        return;
      }
      showErrorToast(graphQLErrors);
    },
  });
};
