import type { AuthSignupFormVO } from './type';

import { authLoginFormSchema } from '@/components/features/auth/AuthLoginForm/const';
import { LABEL_TEXT, PLACEHOLDER } from '@/constants';
import { z } from '@/libs/zod';

export const MIN_LENGTH = {
  PASSWORD: 6,
};

export const authSignupSchema = authLoginFormSchema.extend({
  receivesNewsletter: z.boolean(),
});

export const formInitialValues: AuthSignupFormVO = {
  email: '',
  password: '',
  receivesNewsletter: true,
};

export const formLabel: Record<keyof AuthSignupFormVO, string> = {
  email: LABEL_TEXT.EMAIL,
  password: LABEL_TEXT.PASSWORD,
  receivesNewsletter: LABEL_TEXT.RECEIVES_NEWSLETTER,
};

export const placeholder: Record<keyof AuthSignupFormVO, string> = {
  email: PLACEHOLDER.EMAIL,
  password: PLACEHOLDER.PASSWORD,
  receivesNewsletter: '',
};
