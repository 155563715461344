import { useController } from 'react-hook-form';

import type { FormFieldCheckboxProps } from './type';
import type { InputCheckboxProps } from '@/components/ui/input/InputCheckbox/type';

import { FormFieldWrapper, InputCheckbox } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldCheckbox = ({
  name,
  control,
  shouldUnregister,
  defaultValue,
  checkboxLabel,
  onAfterChange,
  ...props
}: FormFieldCheckboxProps) => {
  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name,
  });

  const handleChange: InputCheckboxProps['onChange'] = (value) => {
    onChange(value);
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputCheckbox
        data-testid={wrapProps.id}
        id={wrapProps.id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef={ref}
        label={checkboxLabel}
        state={error !== undefined ? 'error' : undefined}
        onChange={handleChange}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
