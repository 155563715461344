import {} from 'react';
import { clsx } from 'clsx';

import type { TooltipProps } from './type';

export const Tooltip = ({ text }: TooltipProps) => {
  return (
    <div className="tw-relative tw-inline-flex tw-items-center tw-group">
      <div
        className={clsx(
          'tw-absolute tw-left-0 tw--bottom-3 tw-translate-y-full md:tw-left-6 md:tw-bottom-8 md:tw-translate-y-full',
          'tw-max-w-full tw-min-w-[352px] tw-p-4',
          'tw-bg-black/70 tw-rounded-[8px] tw-text-body14 tw-text-white',
          'tw-transition tw-invisible group-hover:tw-visible'
        )}
      >
        {text}
      </div>
      <button className={clsx('tw-transition')}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.07999 10.0604C8.04999 9.84039 8.03999 9.67039 8.03999 9.51039C8.03999 9.12039 8.11999 8.84039 8.29999 8.59039C8.43999 8.39039 8.60999 8.25039 9.19999 7.84039C9.74999 7.45039 9.92999 7.27039 10.11 6.97039C10.27 6.69039 10.35 6.38039 10.35 6.00039C10.35 4.93039 9.33999 4.15039 7.95999 4.15039C7.16999 4.15039 6.51999 4.35039 6.05999 4.74039C5.64999 5.08039 5.44999 5.42039 5.23999 6.15039L6.07999 6.28039C6.18999 5.86039 6.26999 5.67039 6.43999 5.45039C6.76999 5.04039 7.27999 4.82039 7.92999 4.82039C8.87999 4.82039 9.53999 5.34039 9.53999 6.09039C9.53999 6.39039 9.41999 6.68039 9.18999 6.92039C9.03999 7.07039 8.86999 7.21039 8.50999 7.46039C7.92999 7.86039 7.82999 7.94039 7.62999 8.21039C7.36999 8.56039 7.25999 8.89039 7.25999 9.41039C7.25999 9.74039 7.25999 9.82039 7.21999 10.0604H8.07999ZM7.16999 11.0004V11.9404H8.13999V11.0004H7.16999Z"
            fill="#76828F"
          />
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="7.5"
            stroke="#76828F"
          />
        </svg>
      </button>
    </div>
  );
};
