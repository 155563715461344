import toast, { type ToastType } from 'react-hot-toast';

import type { ValueOrFunction, Renderable, Toast } from 'react-hot-toast';

export const useToast = () => {
  const showToast = (
    status: Extract<ToastType, 'success' | 'error'>,
    message: ValueOrFunction<Renderable, Toast>,
    option?: Pick<Toast, 'id'>
  ) => {
    switch (status) {
      case 'error':
        toast.error(message, option);
        break;

      default:
        toast(message, option);
        break;
    }
  };

  return {
    showToast,
  };
};
