import type { SnsLink } from './type';

import {
  IconSNSFacebook,
  IconSNSInstagram,
  IconSNSTwitter,
} from '@/components/icons';
import { ROUTE, ROUTE_NAME } from '@/constants';

export const footerMenus = [
  {
    path: ROUTE.contestSearch(),
    label: ROUTE_NAME.contestSearch,
  },
  {
    path: ROUTE.articleTop(),
    label: '記事を読む',
  },
  {
    path: ROUTE.service(),
    label: ROUTE_NAME.service,
  },
  {
    path: ROUTE.school(),
    label: '公募スクール',
    target: '_blank',
  },
  {
    path: ROUTE.magazineLatest(),
    label: ROUTE_NAME.magazineLatest,
  },
  {
    path: ROUTE.company(),
    label: ROUTE_NAME.company,
  },
  {
    path: ROUTE.recruitment(),
    label: ROUTE_NAME.recruitment,
  },
  {
    path: ROUTE.contact(),
    label: ROUTE_NAME.contact,
  },
];

export const footerSubMenus = [
  {
    path: ROUTE.company(),
    label: '運営会社',
  },
  {
    path: ROUTE.sitePolicy(),
    label: ROUTE_NAME.sitePolicy,
  },
  {
    path: ROUTE.tokutei(),
    label: ROUTE_NAME.tokutei,
    isShowMiniFooter: true,
  },
  {
    path: ROUTE.privacy(),
    label: ROUTE_NAME.privacy,
    isShowMiniFooter: true,
  },
];

export const snsLinks: SnsLink[] = [
  {
    icon: IconSNSFacebook,
    title: 'Facebook',
    href: 'https://www.facebook.com/kouboguide/',
  },
  {
    icon: IconSNSTwitter,
    title: 'Twitter',
    href: 'https://twitter.com/kouboguide/',
  },
  {
    icon: IconSNSInstagram,
    title: 'Instagram',
    href: 'https://www.instagram.com/kouboguide_mama/',
  },
];
