import type { UrlObject } from 'url';

export const stringifyUrl = (url: UrlObject) => {
  const { pathname } = url;

  if (url.query == null) return pathname;
  const queryString = Object.entries(url.query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  if (queryString.length === 0) {
    return pathname;
  } else {
    return `${pathname}?${queryString}`;
  }
};
