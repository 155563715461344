export const PLACEHOLDER = {
  CARD_NUMBER: '1234 1234 1234 1234',
  CARD_EXPIRED: '月 / 年',
  CARD_CVC: 'CVC',
  FULL_NAME: '公募太郎',
  FIRST_NAME: '太郎',
  LAST_NAME: '公募',
  FULL_NAME_KANA: 'コウボタロウ',
  FIRST_NAME_KANA: 'タロウ',
  LAST_NAME_KANA: 'コウボ',
  PHONE_NUMBER: '0354052170',
  FAX_NUMBER: '0354052061',
  EMAIL: 'koubo@example.com',
  ZIP_CODE: '1050011',
  ADDRESS_LINE1: '港区芝公園',
  ADDRESS_REST: '1丁目8番21号芝公園リッジビル5階',
  ADDRESS_LINE2: '1丁目8番21号',
  ADDRESS_BUILDING: '芝公園リッジビル5階',
  NICKNAME: 'ペンネーム',
  PROFILE: 'プロフィールを記入してください',
  PASSWORD: '',
};
