import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';

import type { AuthLoginFormProps, AuthLoginFormVO } from './type';

import { FormFieldText, Button } from '@/components/ui';
import { useToast } from '@/hooks/utils';

import {
  authLoginFormSchema,
  formInitialValues,
  formLabel,
  placeholder,
} from './const';
import { useLogin } from './hooks';

export const AuthLoginForm = ({ onAfterLogin }: AuthLoginFormProps) => {
  const [loginMutation, loginMutationState] = useLogin();

  const { showToast } = useToast();

  const { control, handleSubmit } = useForm<AuthLoginFormVO>({
    mode: 'onBlur',
    resolver: zodResolver(authLoginFormSchema),
    defaultValues: formInitialValues,
  });

  const submitHandler = (data: AuthLoginFormVO) => {
    loginMutation({
      variables: { user: data },
      onCompleted: () => {
        showToast('success', 'ログインしました');
        onAfterLogin();
      },
    });
  };

  return (
    <form className={clsx('tw-space-y-4')}>
      <FormFieldText
        id="email"
        name="email"
        label={formLabel.email}
        isRequired
        placeholder={placeholder.email}
        control={control}
        onKeyDownEnter={handleSubmit(submitHandler)}
      />
      <FormFieldText
        id="password"
        name="password"
        type="password"
        label={formLabel.password}
        isRequired
        placeholder={placeholder.password}
        control={control}
        onKeyDownEnter={handleSubmit(submitHandler)}
      />
      <Button
        theme="fill"
        onClick={handleSubmit(submitHandler)}
        isLoading={loginMutationState.loading}
      >
        ログイン
      </Button>
    </form>
  );
};
