import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import type { AuthSignupModalProps } from './type';

import { AuthSignupForm } from '@/components/features/auth';
import { BaseModal } from '@/components/ui';
import { ROUTE } from '@/constants';

export const AuthSignupModal = ({
  isOpen,
  onClose,
  onClickLogin,
  onAfterSignup,
}: AuthSignupModalProps) => {
  const router = useRouter();

  const handleAfterSignup = () => {
    if (onAfterSignup !== undefined) {
      onAfterSignup();
    }
    onClose();
    router.push(ROUTE.emailVerification());
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title="新規会員登録"
      ariaLabel="会員登録"
      withCloseButton={true}
      closeOnOverlayClick={true}
      onClose={onClose}
    >
      <div className={clsx('md:tw-max-w-[360px]')}>
        <AuthSignupForm onAfterSignup={handleAfterSignup} />
        <div className={clsx('tw-mt-6 tw-pb-4 tw-text-ui14 tw-text-center')}>
          <span>アカウントをお持ちの方は</span>
          <button
            type="button"
            onClick={onClickLogin}
            className={clsx(
              'tw-text-link hover:tw-opacity-50 tw-transition tw-duration-200'
            )}
          >
            こちら
          </button>
        </div>
      </div>
    </BaseModal>
  );
};
