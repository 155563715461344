import { clsx } from 'clsx';

import type { InputTextProps } from './type';
import type { KeyboardEventHandler } from 'react';

import { INPUT_CLASS_NAMES } from '@/components/ui/input/const';

export const InputText = ({
  inputRef,
  id,
  name,
  type = 'text',
  maxLength,
  autoComplete,
  placeholder = '入力してください',
  inputMode,
  disabled = false,
  value = '',
  theme = 'base',
  size = 'md',
  rounded = 'base',
  roundedDirection = {
    topLeft: true,
    topRight: true,
    bottomLeft: true,
    bottomRight: true,
  },
  state,
  onChange,
  onFocus,
  onBlur,
  onKeyDownEnter,
}: InputTextProps) => {
  const handleKeyDownEnter: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key == 'Enter' && onKeyDownEnter !== undefined) {
      e.preventDefault();
      onKeyDownEnter();
    }
  };

  return (
    <input
      ref={inputRef}
      id={id}
      name={name}
      type={type}
      maxLength={maxLength}
      autoComplete={autoComplete}
      placeholder={placeholder}
      inputMode={inputMode}
      disabled={disabled}
      value={value}
      className={clsx(
        INPUT_CLASS_NAMES({ theme, size, rounded, roundedDirection, state })
      )}
      onChange={(e) => onChange(e.target.value || '')}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyDownEnter}
    />
  );
};
