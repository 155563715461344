import { clsx } from 'clsx';

import type { InputCheckboxProps } from './type';

export const INPUT_THEME_CLASS_NAMES = ({
  disabled,
  theme,
  state,
}: Pick<InputCheckboxProps, 'disabled' | 'theme' | 'state'>) => {
  switch (theme) {
    case 'base': {
      return clsx(
        'tw-border tw-border-gray-400 tw-bg-white',
        disabled && 'tw-bg-gray-300',
        state === 'error' && 'tw-border-error'
      );
    }
    default:
      return '';
  }
};

export const INPUT_SIZE_CLASS_NAMES = ({
  size,
}: Pick<InputCheckboxProps, 'size'>) => {
  switch (size) {
    case 'md': {
      return clsx('tw-h-[18px]', 'tw-w-[18px]');
    }
    default:
      return '';
  }
};

export const BUTTON_CLASS_NAMES = () => {
  return clsx(
    'tw-group',
    'tw-flex tw-items-center tw-gap-2',
    'disabled:tw-cursor-not-allowed'
  );
};

export const INPUT_CLASS_NAMES = ({
  disabled,
  theme,
  size,
  state,
}: Pick<InputCheckboxProps, 'disabled' | 'theme' | 'size' | 'state'>) => {
  return clsx(
    'tw-flex tw-items-center tw-justify-center',
    'tw-rounded-sm tw-overflow-hidden',
    disabled ? 'tw-bg-gray-300' : 'tw-bg-white',
    'tw-outline-none',
    'tw-transition tw-duration-200',
    'focus:tw-outline-none',
    INPUT_THEME_CLASS_NAMES({ disabled, theme, state }),
    INPUT_SIZE_CLASS_NAMES({ size })
  );
};

export const LABEL_CLASS_NAMES = ({
  disabled,
}: Pick<InputCheckboxProps, 'disabled'>) => {
  return clsx(
    'tw-flex-1 tw-text-sm',
    disabled ? 'tw-text-gray-400' : 'tw-text-gray-900'
  );
};
