import type { IconBaseProps } from '@/components/icons/type';

export const IconMyPage = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.5498C14.2091 14.5498 16 12.7589 16 10.5498C16 8.34067 14.2091 6.5498 12 6.5498C9.79086 6.5498 8 8.34067 8 10.5498C8 12.7589 9.79086 14.5498 12 14.5498Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8197 18.2298C19.2397 16.1098 17.2997 14.5498 14.9997 14.5498H8.99969C6.69969 14.5498 4.75969 16.1098 4.17969 18.2298"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
