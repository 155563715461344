import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { FormFieldWrapperProps } from './type';

import { Tooltip } from '@/components/ui';

export const FormFieldWrapper = ({
  id,
  label,
  helperText,
  tooltipText,
  error,
  showLabel = true,
  showError = true,
  isRequired = false,
  showMandatoryMark = true,
  children,
}: FormFieldWrapperProps) => {
  const displayError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  );

  return (
    <div className={clsx('tw-flex tw-flex-col tw-items-start')}>
      {showLabel && (
        <label
          htmlFor={id}
          className={clsx('tw-flex tw-items-center tw-text-ui15')}
        >
          <span>{label}</span>
          {!isRequired && showMandatoryMark && <span>（任意）</span>}
          {tooltipText !== undefined && (
            <span className={clsx('tw-ml-1 tw-flex tw-items-center')}>
              <Tooltip text={tooltipText} />
            </span>
          )}
        </label>
      )}
      <div className={clsx('tw-w-full tw-mt-1')}>
        {children}
        {showError && (
          <p className={clsx('tw-mt-1 tw-text-sm tw-text-error')}>
            {displayError?.message}
          </p>
        )}
        {helperText !== undefined && (
          <div className={clsx('tw-mt-1 tw-text-sm tw-text-gray-600')}>
            {helperText}
          </div>
        )}
      </div>
    </div>
  );
};
