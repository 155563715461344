import { clsx } from 'clsx';

import type { ModalOverlayProps } from './type';

export const ModalOverlay = ({ onClick }: ModalOverlayProps) => {
  return (
    <div
      className={clsx('tw-fixed tw-inset-0 tw-bg-black/60 tw-z-overlay')}
      onClick={() => onClick && onClick()}
    />
  );
};
