import type { IconBaseProps } from '@/components/icons/type';

export const IconChevronRight = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.60909L8.51671 3L17 12L8.51671 21L7 19.3909L13.9666 12L7 4.60909Z"
        fill="currentColor"
      />
    </svg>
  );
};
