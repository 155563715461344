import { clsx } from 'clsx';

import type { ContainerProps } from './type';

import { CONTAINER_MAX_WIDTH_CLASS_NAME } from './const';

export const Container = ({
  type = 'document',
  marginTop = '0',
  children,
}: ContainerProps) => {
  return (
    <div
      className={clsx(
        'tw-w-full tw-ml-auto tw-mr-auto',
        CONTAINER_MAX_WIDTH_CLASS_NAME[type]
      )}
      style={{ marginTop }}
    >
      {children}
    </div>
  );
};
