import { clsx } from 'clsx';
import Link from 'next/link';
import { forwardRef } from 'react';

import type { AnchorLinkProps } from './type';

import { useVersionStore } from '@/stores';
import { stringifyUrl } from '@/utils/url';

export const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  (
    {
      target,
      disabled,
      children,
      className,
      withDefaultHoverBehavior = true,
      ...anchorProps
    },
    ref
  ) => {
    const { requiresRefresh } = useVersionStore();
    const commonClassName = clsx(
      'tw-cursor-pointer',
      withDefaultHoverBehavior &&
        'hover:tw-opacity-50 tw-transition-opacity tw-duration-200',
      disabled && 'tw-pointer-events-none',
      className
    );

    return requiresRefresh ? (
      <a
        {...anchorProps}
        ref={ref}
        href={
          typeof anchorProps.href === 'string'
            ? anchorProps.href
            : stringifyUrl(anchorProps.href) ?? ''
        }
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        className={commonClassName}
      >
        {children}
      </a>
    ) : (
      <Link
        {...anchorProps}
        ref={ref}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        className={commonClassName}
      >
        {children}
      </Link>
    );
  }
);

AnchorLink.displayName === 'AnchorLink';
