import type { FormFieldWrapperPassThroughProps } from './type';

export const extractWrapperProps = <T extends FormFieldWrapperPassThroughProps>(
  props: T & { name: string }
) => {
  const {
    id,
    name,
    label,
    helperText,
    tooltipText,
    isRequired,
    showError,
    showLabel,
    showMandatoryMark,
    ...rest
  } = props;
  return {
    wrapProps: {
      id: id ?? name,
      label,
      helperText,
      tooltipText,
      isRequired,
      showError,
      showLabel,
      showMandatoryMark,
    },
    rest,
  };
};
