import { clsx } from 'clsx';

import type { TextLinkProps } from './type';

import { IconOpenInNew, IconChevronRight } from '@/components/icons';
import { AnchorLink } from '@/components/ui';

import { THEME_CLASS_NAMES } from './const';

export const TextLink = ({
  theme = 'black',
  href = '#',
  isBlank = false,
  withChevron = false,
  children,
  ...anchorProps
}: TextLinkProps) => {
  return (
    <AnchorLink
      href={href}
      target={isBlank ? '_blank' : '_self'}
      className={clsx(
        'tw-inline-flex tw-items-center tw-no-underline',
        THEME_CLASS_NAMES({ theme })
      )}
      {...anchorProps}
    >
      <>
        {children}
        {isBlank && (
          <div className="tw-ml-1">
            <IconOpenInNew boxSize="16px" />
          </div>
        )}
        {withChevron && (
          <div className="tw-ml-1 tw-text-gray-400">
            <IconChevronRight boxSize="16px" />
          </div>
        )}
      </>
    </AnchorLink>
  );
};
