import { clsx } from 'clsx';

import type { InputCheckboxProps } from './type';

import {
  INPUT_CLASS_NAMES,
  BUTTON_CLASS_NAMES,
  LABEL_CLASS_NAMES,
} from './const';

export const InputCheckbox = ({
  inputRef,
  id,
  name,
  disabled = false,
  value = false,
  isIndeterminate = false,
  theme = 'base',
  size = 'md',
  state,
  label,
  withLabel = true,
  onChange,
  onFocus,
  onBlur,
}: InputCheckboxProps) => {
  return (
    <button
      ref={inputRef}
      type="button"
      id={id}
      name={name}
      disabled={disabled}
      className={clsx(BUTTON_CLASS_NAMES())}
      onClick={() => onChange(!value)}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <span
        className={clsx(INPUT_CLASS_NAMES({ disabled, theme, size, state }))}
      >
        {isIndeterminate ? (
          <span
            className={clsx(
              'tw-w-[calc(100%-4px)] tw-h-1 tw-bg-link',
              disabled && 'tw-opacity-70'
            )}
          />
        ) : (
          <span
            className={clsx(
              'tw-w-full tw-h-full',
              value && disabled && 'tw-opacity-70',
              !value && disabled && 'tw-bg-gray-400'
            )}
          >
            {value ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
                  fill="#266ED9"
                />
              </svg>
            ) : null}
          </span>
        )}
      </span>
      {withLabel && label && (
        <span className={clsx(LABEL_CLASS_NAMES({ disabled }))}>{label}</span>
      )}
    </button>
  );
};
