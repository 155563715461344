import { clsx } from 'clsx';

import type { InputStyleProps } from './type';

export const INPUT_THEME_CLASS_NAMES = ({
  theme,
  state,
}: Pick<InputStyleProps, 'theme' | 'state'>) => {
  switch (theme) {
    case 'base': {
      return clsx(
        'tw-bg-gray-100 ',
        'focus:tw-border-[3px] focus:tw-border-link/20',
        'disabled:tw-bg-gray-300 disabled:tw-text-black',
        state === 'error' &&
          'tw-text-error tw-border-2 tw-border-error focus:tw-text-black'
      );
    }
    default:
      return '';
  }
};

export const INPUT_SIZE_CLASS_NAMES = ({
  size,
}: Pick<InputStyleProps, 'size'>) => {
  switch (size) {
    case 'sm': {
      return clsx('tw-h-9 tw-text-sm');
    }
    case 'md': {
      return clsx('tw-h-[52px] tw-text-base');
    }
    default:
      return '';
  }
};

export const INPUT_ROUNDED_CLASS_NAMES = ({
  rounded,
  roundedDirection,
}: Pick<InputStyleProps, 'rounded' | 'roundedDirection'>) => {
  if (roundedDirection) {
    let res = clsx();
    if (rounded === 'base') {
      if (roundedDirection.topLeft) {
        res = clsx(res, 'tw-rounded-tl');
      }
      if (roundedDirection.topRight) {
        res = clsx(res, 'tw-rounded-tr');
      }
      if (roundedDirection.bottomLeft) {
        res = clsx(res, 'tw-rounded-bl');
      }
      if (roundedDirection.bottomRight) {
        res = clsx(res, 'tw-rounded-br');
      }
    }
    return res;
  }
  return clsx();
};

export const INPUT_BASE_CLASS_NAMES = () => {
  return clsx(
    'tw-w-full',
    'tw-px-4',
    'tw-outline-none',
    'tw-transition tw-duration-200',
    'disabled:tw-cursor-not-allowed',
    'placeholder:tw-text-gray-600'
  );
};

export const INPUT_CLASS_NAMES = ({
  theme,
  size,
  rounded,
  roundedDirection,
  state,
}: Pick<
  InputStyleProps,
  'theme' | 'size' | 'rounded' | 'roundedDirection' | 'state'
>) =>
  clsx(
    INPUT_BASE_CLASS_NAMES(),
    INPUT_THEME_CLASS_NAMES({ theme, state }),
    INPUT_SIZE_CLASS_NAMES({ size }),
    INPUT_ROUNDED_CLASS_NAMES({ rounded, roundedDirection })
  );
