import { stringify } from 'querystring';

import { flatten } from 'flat';

import type { PageQuery as ArticleListPageQuery } from '@/components/pages/ArticleList/type';
import type { ParsedContestListUrlQuery } from '@/components/pages/ContestList/type';
import type { PageQuery as MyPageTopPageQuery } from '@/components/pages/MyPageTop/type';
import type { PageQuery as NotificationListPageQuery } from '@/components/pages/NotificationList/type';
import type { PageQuery as TsukuLogSearchResultPageQuery } from '@/components/pages/TsukuLogSearchResult/type';
import type { PageQuery as WorkListPageQuery } from '@/components/pages/WorkList/type';
import type { UrlObject } from 'url';

import { PLANNER } from '@/constants/site';

export const ROUTE = {
  // TOP
  top: () => '/',

  // カテゴリTOP
  categoryTop: (categoryNameEn: string, subcategoryNameEn?: string) =>
    subcategoryNameEn
      ? `/category/${categoryNameEn.replaceAll(
          '_',
          '-'
        )}/${subcategoryNameEn.replaceAll('_', '-')}`
      : `/category/${categoryNameEn.replaceAll('_', '-')}/all`,

  // 管理画面
  adminMagazineOrder: () => '/admin/magazine/order',
  adminMagazineSubscription: () => '/admin/magazine/subscription',
  adminSigninAsUser: () => '/admin/signin-as-user',

  // contest
  contestSearch: () => '/contest',
  contestList: (query: ParsedContestListUrlQuery) => {
    const queryString = stringify(query);
    const pathname = '/contest/list';
    return !queryString ? pathname : `${pathname}?${queryString}`;
  },
  contestDetail: (contestId: number) => `/contest/${contestId}`,

  // プランナー側の応募者マイページURL
  applicantMyPage: (slug: string) => `${PLANNER.DOMAIN}/mypage/${slug}`,

  // article
  articleTop: () => '/article',
  articleList: (
    query: Partial<Pick<ArticleListPageQuery, 'keyword' | 'page' | 'perPage'>>
  ) => ({
    pathname: '/article/list',
    query: flatten<Partial<ArticleListPageQuery>, UrlObject['query']>(query),
  }),
  articleListByCategory: (
    categoryId: number,
    query: Partial<Pick<ArticleListPageQuery, 'page' | 'perPage'>>
  ) => ({
    pathname: `/article/list/category/${categoryId}`,
    query: flatten<Partial<ArticleListPageQuery>, UrlObject['query']>(query),
  }),
  articleListBySubCategory: (
    subCategoryId: number,
    query: Partial<
      Pick<ArticleListPageQuery, 'page' | 'perPage' | 'categoryId'>
    >
  ) => ({
    pathname: `/article/list/category/sub/${subCategoryId}`,
    query: flatten<Partial<ArticleListPageQuery>, UrlObject['query']>(query),
  }),
  articleListByTag: (
    tagId: number,
    query: Partial<Pick<ArticleListPageQuery, 'page' | 'perPage'>>
  ) => ({
    pathname: `/article/list/tag/${tagId}`,
    query: flatten<Partial<ArticleListPageQuery>, UrlObject['query']>(query),
  }),
  articleDetail: (articleId: number) => `/article/${articleId}`,

  landingPage: (slug: string) => `/lp/${slug}`,

  // notification
  notificationList: (query: Partial<NotificationListPageQuery>) => ({
    pathname: '/notification',
    query: flatten<Partial<NotificationListPageQuery>, UrlObject['query']>(
      query
    ),
  }),
  notificationDetail: (notificationId: number) =>
    `/notification/${notificationId}`,

  // auth
  emailVerification: () => '/email-verification',
  forgetPassword: () => '/forgot-password',
  resetPassword: () => '/reset-password',

  // cart
  cart: '/cart',
  cartComplete: '/cart/complete',

  // magazin
  magazineLatest: () => '/magazine/latest',
  magazineDetail: (magazineId: number) => `/magazine/${magazineId}`,
  magazineSubscription: () => '/magazine/subscription',
  // subscription
  magazineSubscriptionRegister: '/magazine/subscription/register',
  magazineSubscriptionComplete: '/magazine/subscription/complete',

  // mypage
  mypageTop: (
    withOnboarding?: `?${keyof Pick<MyPageTopPageQuery, 'onboarding'>}=true`
  ) => `/mypage${withOnboarding ?? ''}`,
  favoriteTop: () => '/mypage/favorite',
  favoriteContest: () => '/mypage/favorite/contest',
  favoritePost: () => '/mypage/favorite/post',
  favoriteWork: () => '/mypage/favorite/work',
  favoriteTsukuLog: () => '/mypage/favorite/tsukulog',
  orderHistories: () => '/mypage/order',
  orderHistoryDetail: (orderId: string) => `/mypage/order/${orderId}`,
  digitalMagazines: () => '/mypage/digital',
  digitalMagazinesFeature: () => '/mypage/digital/feature',
  profileSetting: () => '/mypage/profile',
  accountSetting: () => '/mypage/account',

  // profile
  profile: (creatorId: number) => `/tsukulog/profile/${creatorId}`,
  profileFolloweeList: (userId: number) => `/tsukulog/profile/${userId}/follow`,
  profileFollowerList: (userId: number) =>
    `/tsukulog/profile/${userId}/follower`,

  // tsukuLog
  tsukuLogTop: () => '/tsukulog',
  tsukuLogSearch: (query: TsukuLogSearchResultPageQuery) => ({
    pathname: '/tsukulog/search',
    query: flatten<Partial<TsukuLogSearchResultPageQuery>, UrlObject['query']>(
      query
    ),
  }),
  tsukuLogDetail: (creatorPostId: number) => `/tsukulog/${creatorPostId}`,

  work: () => '/work',
  workSearch: (query: WorkListPageQuery) => ({
    pathname: '/work/search',
    query: flatten<Partial<WorkListPageQuery>, UrlObject['query']>(query),
  }),
  workDetail: (id: number) => `/work/${id}`,

  // student
  student: (typeNameEn: string) => `/student/${typeNameEn}`,

  // others
  brandRenewal: () => '/hello-koubo',
  service: () => '/service',
  contact: () => '/contact',
  sitePolicy: () => '/sitepolicy',
  tokutei: () => '/tokutei',
  privacy: () => '/privacy',
  privacyAgreement: () => '/privacy/agreement',
  company: () => 'https://koubo.co.jp',
  recruitment: () => 'https://koubo.co.jp/recruitment',
  school: () => 'https://school.koubo.co.jp',
  publicationRequest: () => 'https://koubo.co.jp/publication-request',
  companyService: () => 'https://koubo.co.jp/service',
  planner: () => 'https://planner.koubo.jp',

  // エラー画面
  error404: () => '/404',
  error500: () => '/500',

  feed: () => '/api/feed',
  rssGunosy: () => '/rss/gunosy',

  sitemap: () => '/sitemap',
};

export const ROUTE_NAME: Record<keyof typeof ROUTE, string> = {
  top: 'TOP',

  categoryTop: 'カテゴリTOP',

  adminMagazineOrder: '雑誌の注文管理',
  adminMagazineSubscription: '雑誌の定期購読管理',
  adminSigninAsUser: '代理ログイン',

  contestSearch: '公募を探す',
  contestList: '検索結果',
  contestDetail: '公募詳細',

  applicantMyPage: '応募者マイページ',

  articleTop: '記事を読む',
  articleList: '記事を読むキーワード検索',
  articleListByCategory: '記事を読むカテゴリ検索',
  articleListBySubCategory: '記事を読むサブカテゴリ検索',
  articleListByTag: '記事を読むタグ検索',

  articleDetail: '記事を読む詳細',

  landingPage: 'LP',

  notificationList: 'お知らせ一覧',
  notificationDetail: 'お知らせ詳細',

  emailVerification: 'メール認証',
  forgetPassword: 'パスワードをお忘れの方へ',
  resetPassword: 'パスワード再設定',

  cart: 'カートの中身確認',
  cartComplete: '決済完了画面',

  magazineSubscriptionRegister: '定期購読の申し込み',
  magazineSubscriptionComplete: '定期購読の申し込み完了',

  magazineLatest: '季刊公募ガイド',
  magazineDetail: '月間公募ガイド',
  magazineSubscription: '定期購読について',

  mypageTop: 'マイページ',
  favoriteTop: 'お気に入り',
  favoriteContest: 'お気に入り公募リスト',
  favoritePost: 'お気に入り投稿リスト',
  favoriteWork: 'お気に入り作品リスト',
  favoriteTsukuLog: 'お気に入りつくログリスト',
  orderHistories: '購入履歴',
  orderHistoryDetail: '購入履歴詳細',
  digitalMagazines: '電子版を読む',
  digitalMagazinesFeature: '特集電子版を読む',
  profileSetting: 'プロフィール設定',
  accountSetting: 'アカウント設定・定期購読設定',

  // profile
  profile: 'プロフィール',
  profileFolloweeList: 'フォローリスト',
  profileFollowerList: 'フォロワーリスト',

  // tsukuLog
  tsukuLogTop: 'つくログ',
  tsukuLogSearch: 'つくログ検索',
  tsukuLogDetail: 'つくログ詳細',

  work: '投稿する',
  workSearch: '作品検索',
  workDetail: '作品詳細',

  // student
  student: '学生向けコンテスト',

  brandRenewal: 'ブランドリニューアル',
  service: '会員限定サービスのご案内',
  sitePolicy: 'サイトのご利用にあたって',
  tokutei: '特定商取引法に基づく表記',
  privacy: '個人情報保護方針',
  privacyAgreement: '個人情報のお取扱いに関する同意事項',
  company: '公募を主催される方へ',
  recruitment: '採用情報',
  contact: 'お問い合わせ',
  school: '公募スクール',
  publicationRequest: '無料掲載お申し込み',
  companyService: 'サービス',
  planner: 'Kouboプランナー',

  error404: 'ページが見つかりませんでした',
  error500: 'このページは現在ご利用いただけません。',

  feed: 'feed',
  rssGunosy: 'RSS グノシー',

  sitemap: 'sitemap',
};
