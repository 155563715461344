import { clsx } from 'clsx';

import type { ButtonStyleProps } from './type';

const BUTTON_THEME_CLASS_NAMES = ({
  theme,
}: Pick<ButtonStyleProps, 'theme'>) => {
  switch (theme) {
    case 'fill': {
      return clsx(
        'tw-bg-primary-500 tw-text-white tw-font-bold',
        'hover:enabled:tw-bg-primary-600',
        'disabled:tw-bg-gray-300 disabled:tw-text-gray-500'
      );
    }
    case 'outline': {
      return clsx(
        'tw-bg-white tw-text-black tw-border tw-border-gray-400',
        'hover:enabled:tw-bg-gray-100',
        'disabled:tw-text-gray-500'
      );
    }
    case 'text': {
      return clsx(
        'tw-bg-transparent tw-text-black',
        'hover:enabled:tw-opacity-70',
        'disabled:tw-text-gray-500'
      );
    }
    case 'negative': {
      return clsx(
        'tw-bg-white tw-text-error tw-border tw-border-error',
        'hover:enabled:tw-bg-error/10',
        'disabled:tw-text-gray-500 disabled:tw-border-gray-400'
      );
    }
    default:
      return '';
  }
};

const BUTTON_SIZE_CLASS_NAMES = ({ size }: Pick<ButtonStyleProps, 'size'>) => {
  switch (size) {
    case 'sm': {
      return clsx('tw-h-9 tw-px-4 tw-text-ui14');
    }
    case 'md': {
      return clsx('tw-h-[51px] tw-px-8 tw-text-ui16');
    }
    default:
      return '';
  }
};

const BUTTON_ROUNDED_CLASS_NAMES = ({
  rounded,
  roundedDirection,
}: Pick<ButtonStyleProps, 'rounded' | 'roundedDirection'>) => {
  if (roundedDirection === undefined) {
    return '';
  }
  switch (rounded) {
    case 'md': {
      return clsx('tw-rounded-full');
    }
    default:
      return '';
  }
};

export const BUTTON_CLASS_NAMES = ({
  theme,
  size,
  rounded,
  roundedDirection,
}: Pick<ButtonStyleProps, 'theme' | 'size' | 'rounded' | 'roundedDirection'>) =>
  clsx(
    'tw-inline-flex tw-items-center tw-justify-center',
    'tw-outline-none',
    'tw-transition tw-duration-200',
    BUTTON_THEME_CLASS_NAMES({ theme }),
    BUTTON_SIZE_CLASS_NAMES({ size }),
    BUTTON_ROUNDED_CLASS_NAMES({ rounded, roundedDirection })
  );
